import React, { useState, useEffect } from 'react';
import { Mail, Zap, Shield, ArrowRight, Factory, Building2, DollarSign, TrendingUp, Check, BarChart, Settings, Wrench, Package, Truck } from 'lucide-react';
import Cal, { getCalApi } from "@calcom/embed-react";

const manufacturingBenefits = [
  { 
    icon: Building2, 
    title: "Construction Client Acquisition", 
    content: "Connect with major construction firms\nSecure long-term supply contracts\nBecome a preferred manufacturer"
  },
  { 
    icon: Wrench, 
    title: "Manufacturing Process Edge", 
    content: "Streamline quote delivery\nOptimize production planning\nImprove delivery timelines"
  },
  { 
    icon: Package, 
    title: "Supply Chain Integration", 
    content: "Early access to construction plans\nPreferred manufacturer status\nAdvance notice on material needs"
  },
  { 
    icon: Truck, 
    title: "Market Expansion", 
    content: "Enter new construction markets\nDiversify product offerings\nExpand your client portfolio"
  }
];

const manufacturingChallenges = [
  { title: "Limited Project Access", description: "Struggling to connect with major construction firms" },
  { title: "Quote Response Time", description: "Not delivering quotes fast enough to win contracts" },
  { title: "Supplier Competition", description: "Difficulty standing out among other manufacturers" },
  { title: "Project Planning", description: "Missing early planning phases for material needs" },
  { title: "Client Diversification", description: "Over-reliance on a small number of buyers" },
  { title: "Market Position", description: "Losing opportunities to competing manufacturers" }
];

export default function Manufacturing() {
  // Add ref at the top of the component
  const contactRef = React.useRef(null);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({ "namespace": "30min-meeting" });
      cal("ui", {
        "styles": { 
          "branding": { "brandColor": "#93c5fd" },
          "theme": "light"
        },
        "hideEventTypeDetails": false,
        "layout": "column_view"
      });
    })();
  }, []);

  return (
    <div className="min-h-screen bg-[#f7f5ed] text-black font-mono relative">
      <main className="container mx-auto px-4 py-8 sm:py-12">
        {/* Hero section */}
        <section className="text-center mb-12 sm:mb-20">
          <div className="bg-[#e8eef7] text-black py-2 px-4 mb-6 sm:mb-8 inline-block rounded-full text-base sm:text-lg font-extrabold font-sans tracking-wider transform -rotate-2">
            ATTENTION MANUFACTURERS: WIN MORE CONSTRUCTION CONTRACTS
          </div>
          <h1 className="text-3xl sm:text-4xl md:text-6xl font-bold mb-4 sm:mb-6 font-mono">
            Manufacturing Sales Protocol
          </h1>
          <p className="text-lg sm:text-xl mb-6 sm:mb-8">
            We help manufacturing companies secure 5-7 major construction supply contracts per quarter through targeted outreach to construction firms
          </p>
          <div className="flex justify-center mb-8">
            <a 
              href="#contact" 
              className="inline-flex items-center bg-[#2d3748] text-white px-6 py-3 rounded-full text-base sm:text-lg font-semibold hover:bg-[#1a202c] transition-colors"
              onClick={(e) => {
                e.preventDefault();
                contactRef.current?.scrollIntoView({ 
                  behavior: 'smooth',
                  block: 'start'
                });
              }}
            >
              Book a Consultation <ArrowRight className="ml-2" />
            </a>
          </div>
        </section>

        {/* Metrics section */}
        <section className="mb-12 sm:mb-20">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { icon: BarChart, title: '180% More Contracts', description: 'Average increase in successful manufacturing deals' },
              { icon: Building2, title: 'Manufacturing Focus', description: 'Specialized in construction material suppliers' },
              { icon: TrendingUp, title: 'Long-Term Partners', description: 'Average client relationship spans 4+ years' },
            ].map((metric, index) => (
              <div 
                key={index} 
                className="bg-[#e8e4d8] p-6 rounded-lg text-center relative overflow-hidden"
                style={{
                  boxShadow: '4px 4px 0 0 #000',
                  border: '2px solid #000',
                  transform: 'translate(-4px, -4px)',
                  backgroundColor: '#f0ece4'
                }}
              >
                <metric.icon className="w-12 h-12 mx-auto mb-4 text-[#2d3748]" />
                <h3 className="text-xl font-semibold mb-2 text-[#2d3748]">{metric.title}</h3>
                <p className="text-[#4a5568]">{metric.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Benefits section */}
        <section className="mb-12 sm:mb-20">
          <h2 className="text-4xl font-bold mb-8 text-center text-[#2d3748]">Construction-Specific Solutions</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            {manufacturingBenefits.map((benefit, index) => (
              <div 
                key={index}
                className="bg-[#f0ece4] p-6 rounded-lg relative overflow-hidden"
                style={{
                  boxShadow: '4px 4px 0 0 #000',
                  border: '2px solid #000',
                  transform: 'translate(-4px, -4px)'
                }}
              >
                <div className="flex items-center mb-4">
                  <benefit.icon className="w-8 h-8 text-[#2d3748] mr-3" />
                  <h3 className="text-xl font-semibold text-[#2d3748]">{benefit.title}</h3>
                </div>
                <ul className="space-y-2">
                  {benefit.content.split('\n').map((item, i) => (
                    <li key={i} className="flex items-center">
                      <Check className="w-5 h-5 text-[#2d3748] mr-2 flex-shrink-0" />
                      <span className="text-[#4a5568]">{item}</span>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </section>

        {/* Email Introduction section */}
        <section className="mb-16 sm:mb-24 text-center">
          <h2 className="text-3xl font-bold mb-6 text-[#2d3748] font-mono inline-block relative">
            Why Email Works for Manufacturing Sales
            <div className="absolute -bottom-2 left-0 w-full h-1 bg-[#93c5fd] opacity-50"></div>
          </h2>
          <p className="text-[#4a5568] text-lg max-w-3xl mx-auto leading-relaxed">
            Transform your manufacturing business with targeted email outreach that connects you directly with construction decision-makers.
          </p>
        </section>

        {/* Direct Line to Decision Makers section */}
        <section className="mb-16 sm:mb-24 bg-gradient-to-br from-[#f0ece4] to-white p-12 rounded-lg relative overflow-hidden" style={{
          boxShadow: '4px 4px 0 0 #000',
          border: '2px solid #000',
          transform: 'translate(-4px, -4px)',
        }}>
          <div className="absolute top-0 right-0 w-32 h-32 bg-[#93c5fd] rounded-bl-full opacity-10"></div>
          <div className="relative">
            <h3 className="text-2xl font-semibold text-[#2d3748] font-mono mb-8">
              Email: Your Direct Line to Construction Buyers
            </h3>
            <div className="space-y-6 max-w-3xl">
              <p className="text-[#4a5568] text-lg leading-relaxed">
                In the construction industry, getting face time with procurement teams is challenging. Email remains the most effective way to reach busy construction executives and purchasing managers.
              </p>
              <p className="text-[#4a5568] text-lg leading-relaxed">
                Our approach transforms cold outreach into warm introductions, connecting manufacturers directly with the people who make material purchasing decisions.
              </p>
            </div>
          </div>
        </section>

        {/* Why Email Outreach Excels section */}
        <section className="mb-16 sm:mb-24 bg-gradient-to-br from-white to-[#f0ece4] p-12 rounded-lg relative overflow-hidden" style={{
          boxShadow: '4px 4px 0 0 #000',
          border: '2px solid #000',
          transform: 'translate(-4px, -4px)',
        }}>
          <div className="absolute top-0 left-0 w-32 h-32 bg-[#93c5fd] rounded-br-full opacity-10"></div>
          <div className="relative">
            <h3 className="text-2xl font-semibold text-[#2d3748] font-mono mb-8">
              Why Email Outreach Excels
            </h3>
            <ul className="space-y-4 max-w-3xl">
              {[
                "Early access to upcoming construction projects",
                "Direct reach to multiple decision-makers simultaneously",
                "Scalable outreach to hundreds of potential clients",
                "Detailed tracking of engagement and responses",
                "Professional documentation of all communications"
              ].map((item, index) => (
                <li key={index} className="flex items-start group">
                  <div className="w-6 h-6 rounded-full border-2 border-[#2d3748] flex items-center justify-center mr-3 flex-shrink-0 mt-1 group-hover:bg-[#93c5fd] group-hover:border-[#93c5fd] transition-colors">
                    <Check className="w-4 h-4 text-[#2d3748] group-hover:text-white" />
                  </div>
                  <span className="text-[#4a5568] text-lg">{item}</span>
                </li>
              ))}
            </ul>
          </div>
        </section>

        {/* Key Benefits section */}
        <section className="mb-16 sm:mb-24">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {[
              { 
                title: "High-Volume Outreach",
                description: "Connect with hundreds of construction buyers efficiently",
                gradient: "from-[#93c5fd] to-[#f0ece4]"
              },
              { 
                title: "Precision Targeting",
                description: "Reach exactly the right procurement teams",
                gradient: "from-[#f0ece4] to-[#93c5fd]"
              },
              { 
                title: "Rapid Results",
                description: "See responses and book meetings within days",
                gradient: "from-[#93c5fd] to-[#f0ece4]"
              }
            ].map((feature, index) => (
              <div 
                key={index}
                className={`bg-gradient-to-br ${feature.gradient} p-8 rounded-lg text-center relative group transition-transform duration-300 hover:-translate-y-1`}
                style={{
                  boxShadow: '4px 4px 0 0 #000',
                  border: '2px solid #000',
                  transform: 'translate(-4px, -4px)'
                }}
              >
                <div className="absolute inset-0 bg-white opacity-90 rounded-lg"></div>
                <div className="relative">
                  <h4 className="text-xl font-semibold mb-4 text-[#2d3748] font-mono">{feature.title}</h4>
                  <p className="text-[#4a5568] text-lg leading-relaxed">{feature.description}</p>
                </div>
              </div>
            ))}
          </div>
        </section>

        {/* Combined Guide and CTA section */}
        <section ref={contactRef} id="contact" className="mb-16 sm:mb-24">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-6 text-[#2d3748] font-mono">Scale Your Manufacturing Business</h2>
            <p className="text-xl font-semibold text-[#4a5568] mb-6 max-w-3xl mx-auto">
              Ready to become a preferred manufacturer for major construction projects?
            </p>
          </div>

          <div className="max-w-4xl mx-auto">
            <Cal 
              namespace="30min-meeting"
              calLink="sayedb/30min-meeting"
              style={{
                width: "100%",
                height: "800px",
              }}
              config={{
                layout: "month_view",
                hideEventTypeDetails: false,
                styling: {
                  margin: "0 auto"
                },
                theme: "light"
              }}
            />
          </div>
        </section>
      </main>

      <footer className="bg-[#f0ece4] py-6 text-center mt-12">
        <p className="text-sm sm:text-base text-[#4a5568]">&copy; 2024 The Email Protocol - For Manufacturers</p>
      </footer>
    </div>
  );
} 