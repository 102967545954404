import React, { useEffect, useRef } from 'react';
import { ArrowRight, Mail, Users, DollarSign, TrendingUp, Check, Calendar, ChartBar, Target, Zap } from 'lucide-react';
import Cal, { getCalApi } from "@calcom/embed-react";

const benefits = [
  {
    title: "Accelerated Growth",
    description: "Generate 30+ qualified meetings monthly with decision-makers in your target market",
    icon: Zap,
  },
  {
    title: "Market Validation",
    description: "Build documented proof of market demand that investors want to see",
    icon: Target,
  },
  {
    title: "Revenue Metrics",
    description: "Create the consistent revenue patterns that make investors compete for your business",
    icon: ChartBar,
  },
];

const features = [
  "Proven outreach strategy customized for your market",
  "Weekly performance metrics and growth tracking",
  "Dedicated campaign management team",
  "Multi-channel prospect engagement",
  "Continuous optimization and testing",
  "Full integration with your sales process"
];

export default function MRandA() {
  const contactRef = useRef(null);

  useEffect(() => {
    (async function () {
      const cal = await getCalApi({ "namespace": "30min-meeting" });
      cal("ui", {
        "styles": { 
          "branding": { "brandColor": "#FFD700" },
          "theme": "light"
        },
        "hideEventTypeDetails": false,
        "layout": "column_view"
      });
    })();
  }, []);

  return (
    <div className="min-h-screen bg-[#f7f5ed] text-black font-mono">
      <main className="container mx-auto px-4 py-12">
        {/* Hero Section */}
        <section className="text-center mb-20">
          <div 
            className="inline-flex items-center bg-yellow-300/90 text-black py-2 px-6 mb-6 rounded-full text-sm font-bold border-2 border-black relative group"
            style={{
              animation: 'float 3s ease-in-out infinite',
              boxShadow: '4px 4px 0 0 #000',
              transform: 'translate(-4px, -4px)',
            }}
          >
            <Mail className="w-4 h-4 mr-2 animate-bounce" />
            <span className="relative inline-block">
              <span className="relative z-10 group-hover:animate-pulse">EXCLUSIVE MR&A CLIENT OFFER</span>
              <span 
                className="absolute inset-0 bg-yellow-400 transform scale-x-0 group-hover:scale-x-100 transition-transform origin-left"
                style={{
                  zIndex: 0,
                  height: '2px',
                  bottom: '-2px',
                }}
              ></span>
            </span>
            <style jsx>{`
              @keyframes float {
                0%, 100% { transform: translate(-4px, -4px); }
                50% { transform: translate(-4px, -12px); }
              }
            `}</style>
          </div>
          <h1 className="text-4xl md:text-6xl font-bold mb-6">
            Turn Market Potential Into<br />
            <span className="text-orange-600">Investor-Ready Revenue</span>
          </h1>
          <p className="text-xl mb-8 max-w-2xl mx-auto text-gray-700">
            Partner with The Email Protocol to build the consistent revenue growth and market validation that makes your business irresistible to investors.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center">
            <a 
              href="#contact" 
              className="inline-flex items-center bg-orange-600 text-white px-8 py-4 rounded-full text-lg font-semibold hover:bg-orange-500 transition-all transform hover:-translate-y-1 shadow-lg"
              onClick={(e) => {
                e.preventDefault();
                contactRef.current?.scrollIntoView({ 
                  behavior: 'smooth',
                  block: 'start'
                });
              }}
            >
              Book a Consultation <ArrowRight className="ml-2" />
            </a>
          </div>
        </section>

        {/* Stats Section */}
        <section className="mb-20">
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-8 rounded-lg shadow-lg border-2 border-black text-center">
              <div className="text-4xl font-bold text-orange-600 mb-2">30+</div>
              <div className="text-gray-700">Qualified Meetings Per Month</div>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-lg border-2 border-black text-center">
              <div className="text-4xl font-bold text-orange-600 mb-2">3x</div>
              <div className="text-gray-700">Average Revenue Growth</div>
            </div>
            <div className="bg-white p-8 rounded-lg shadow-lg border-2 border-black text-center">
              <div className="text-4xl font-bold text-orange-600 mb-2">14 Days</div>
              <div className="text-gray-700">To First Results</div>
            </div>
          </div>
        </section>

        {/* Benefits Grid */}
        <section className="mb-20">
          <h2 className="text-3xl font-bold mb-12 text-center">How We Build Your Investment Case</h2>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            {benefits.map((benefit, index) => (
              <div 
                key={index}
                className="bg-white p-8 rounded-lg shadow-lg border-2 border-black hover:transform hover:-translate-y-1 transition-all"
              >
                <benefit.icon className="w-12 h-12 text-orange-600 mb-4" />
                <h3 className="text-xl font-bold mb-3">{benefit.title}</h3>
                <p className="text-gray-700">{benefit.description}</p>
              </div>
            ))}
          </div>
        </section>

        {/* Process Section */}
        <section className="mb-20 bg-white p-8 rounded-lg shadow-lg border-2 border-black">
          <h2 className="text-3xl font-bold mb-12 text-center">Your Growth Journey</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div>
              <h3 className="text-xl font-bold mb-6">What You Get</h3>
              <ul className="space-y-4">
                {features.map((feature, index) => (
                  <li key={index} className="flex items-center">
                    <Check className="w-5 h-5 text-green-500 mr-3 flex-shrink-0" />
                    <span className="text-gray-700">{feature}</span>
                  </li>
                ))}
              </ul>
            </div>
            <div>
              <h3 className="text-xl font-bold mb-6">Timeline to Results</h3>
              <div className="space-y-6">
                <div className="flex items-start">
                  <div className="bg-orange-100 rounded-full p-2 mr-4">
                    <Calendar className="w-6 h-6 text-orange-600" />
                  </div>
                  <div>
                    <h4 className="font-bold mb-1">Days 1-3</h4>
                    <p className="text-gray-700">Strategy development and campaign setup</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="bg-orange-100 rounded-full p-2 mr-4">
                    <Mail className="w-6 h-6 text-orange-600" />
                  </div>
                  <div>
                    <h4 className="font-bold mb-1">Days 4-7</h4>
                    <p className="text-gray-700">Initial outreach and optimization</p>
                  </div>
                </div>
                <div className="flex items-start">
                  <div className="bg-orange-100 rounded-full p-2 mr-4">
                    <Users className="w-6 h-6 text-orange-600" />
                  </div>
                  <div>
                    <h4 className="font-bold mb-1">Days 8-21</h4>
                    <p className="text-gray-700">First meetings and revenue generation</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* Cold Email Benefits Section */}
        <section className="mb-20">
          <h2 className="text-3xl font-bold mb-8 text-center">Believe in Cold Email Again</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div className="bg-orange-600 text-white p-8 rounded-lg shadow-lg" style={{
              boxShadow: '4px 4px 0 0 #000',
              border: '3px solid #000',
              transform: 'translate(-4px, -4px)',
            }}>
              <h3 className="text-xl font-semibold mb-4">Cold Email: The Misunderstood Giant</h3>
              <p className="mb-4">Many have lost faith in cold email, but it remains one of the most powerful tools for B2B growth. When done right, it's not just effective—it's transformative.</p>
              <p>Our approach reignites the potential of cold email, turning skeptics into believers and leads into long-term partnerships.</p>
            </div>
            <div className="bg-orange-600 text-white p-8 rounded-lg shadow-lg" style={{
              boxShadow: '4px 4px 0 0 #000',
              border: '3px solid #000',
              transform: 'translate(-4px, -4px)',
            }}>
              <h3 className="text-xl font-semibold mb-4">Why Cold Email Still Reigns Supreme</h3>
              <ul className="list-disc pl-5 space-y-3">
                <li>Direct access to decision-makers</li>
                <li>Highly scalable outreach</li>
                <li>Precise targeting capabilities</li>
                <li>Measurable ROI and performance</li>
                <li>Ability to start meaningful conversations</li>
              </ul>
            </div>
          </div>
        </section>

        {/* Exclusive MR&A Offer Section */}
        <section className="mb-20 bg-[#fef9f1] p-12 rounded-2xl">
          <div className="text-center mb-12">
            <div 
              className="inline-flex items-center bg-[#FFD700] text-black py-3 px-8 rounded-full text-base font-mono font-bold relative mx-auto"
              style={{
                boxShadow: '4px 4px 8px rgba(0, 0, 0, 0.3)',
                border: '2px solid black',
                transform: 'translateY(-1px)',
              }}
            >
              <Mail className="w-5 h-5 mr-3" />
              EXCLUSIVE MR&A CLIENT OFFER
            </div>
            <h2 className="text-3xl font-bold mt-8">Special Partnership Benefits</h2>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
            <div className="bg-white p-8 rounded-xl shadow-lg border-2 border-[#FFD700] hover:border-black transition-all">
              <div className="bg-[#FFD700] rounded-full w-16 h-16 flex items-center justify-center mb-6 border-2 border-black shadow-md">
                <DollarSign className="w-8 h-8 text-black" />
              </div>
              <h3 className="text-xl font-bold mb-3">Preferred Pricing</h3>
              <p className="text-gray-700">Exclusive rates available only to MR&A clients, designed to accelerate your path to investment readiness.</p>
            </div>
            
            <div className="bg-white p-8 rounded-xl shadow-lg border-2 border-[#FFD700] hover:border-black transition-all">
              <div className="bg-[#FFD700] rounded-full w-16 h-16 flex items-center justify-center mb-6 border-2 border-black shadow-md">
                <Zap className="w-8 h-8 text-black" />
              </div>
              <h3 className="text-xl font-bold mb-3">Accelerated Setup</h3>
              <p className="text-gray-700">Priority onboarding and campaign launch to align with your investment preparation timeline.</p>
            </div>
            
            <div className="bg-white p-8 rounded-xl shadow-lg border-2 border-[#FFD700] hover:border-black transition-all">
              <div className="bg-[#FFD700] rounded-full w-16 h-16 flex items-center justify-center mb-6 border-2 border-black shadow-md">
                <Target className="w-8 h-8 text-black" />
              </div>
              <h3 className="text-xl font-bold mb-3">Strategic Alignment</h3>
              <p className="text-gray-700">Tailored approach that complements MR&A's investment readiness strategy for your business.</p>
            </div>
          </div>

          <div className="mt-12 bg-white p-8 rounded-xl border-2 border-[#FFD700]">
            <h3 className="text-xl font-bold mb-6 text-center">Why This Partnership Works</h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              <div className="bg-[#fef9f1] p-6 rounded-lg border-2 border-[#FFD700]">
                <h4 className="font-bold mb-4 flex items-center">
                  <Users className="w-5 h-5 mr-2 text-black" />
                  For Your Business:
                </h4>
                <ul className="space-y-3">
                  <li className="flex items-center">
                    <Check className="w-5 h-5 text-black mr-2 flex-shrink-0" />
                    <span>Faster path to investment readiness</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 text-black mr-2 flex-shrink-0" />
                    <span>Proven revenue generation system</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 text-black mr-2 flex-shrink-0" />
                    <span>Seamless integration with MR&A strategy</span>
                  </li>
                </ul>
              </div>
              <div className="bg-[#fef9f1] p-6 rounded-lg border-2 border-[#FFD700]">
                <h4 className="font-bold mb-4 flex items-center">
                  <Zap className="w-5 h-5 mr-2 text-black" />
                  Our Commitment:
                </h4>
                <ul className="space-y-3">
                  <li className="flex items-center">
                    <Check className="w-5 h-5 text-black mr-2 flex-shrink-0" />
                    <span>Priority support and optimization</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 text-black mr-2 flex-shrink-0" />
                    <span>Investment-focused reporting</span>
                  </li>
                  <li className="flex items-center">
                    <Check className="w-5 h-5 text-black mr-2 flex-shrink-0" />
                    <span>Flexible terms for MR&A clients</span>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* CTA Section */}
        <section ref={contactRef} id="contact" className="text-center mb-20">
          <h2 className="text-3xl font-bold mb-4">Ready to Build Your Investment Case?</h2>
          <p className="text-xl mb-8 max-w-2xl mx-auto">
            Join other MR&A clients who have transformed their growth trajectory and secured investment through proven revenue generation.
          </p>
          
          <div className="max-w-4xl mx-auto">
            <Cal 
              namespace="30min-meeting"
              calLink="sayedb/30min-meeting"
              style={{
                width: "100%",
                height: "800px",
              }}
              config={{
                layout: "month_view",
                hideEventTypeDetails: false,
                styling: {
                  margin: "0 auto"
                },
                theme: "light"
              }}
            />
          </div>
        </section>
      </main>
    </div>
  );
} 